import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Button, Content, CTABox, Spacer } from 'components';
export const _frontmatter = {
  "title": "Subscribed!"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Content mdxType="Content">
	<Spacer height={100} responsive={{
        md: 80,
        sm: 60
      }} mdxType="Spacer" />
	<h1>High five!</h1>
	<p>
		You're now subscribed and we'll notify you when we release the beta
		version of the app.
	</p>
	<p>
		In the meantime you can get in touch with us via our{' '}
		<a href="https://github.com/BracketSpace/MadNotes/discussions">
			Discussion board
		</a>
		. We're open for the feature requests too!
	</p>
	<p>Stay tuned! 💜</p>
    </Content>
    <Spacer height={60} responsive={{
      md: 40,
      sm: 30
    }} mdxType="Spacer" />
    <CTABox color="banana99" description="Share your ideas and let us know you're waiting for the release!" heading="Shape up our product" mdxType="CTABox">
	<Button arrow="right" color="berry99" to="https://github.com/BracketSpace/MadNotes/discussions" type="plain" mdxType="Button">
		Join Community
	</Button>
	<Button color="berry99" to="/#pricing" type="ghost" gtag={['event', 'click', {
        event_label: 'Pricing'
      }]} mdxType="Button">
		See Pricing
	</Button>
    </CTABox>
    <Spacer height={100} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      